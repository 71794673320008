import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {
    employmentAnswers,
    employedStatuses,
    translateAnswers,
} from 'clinical/helpers.js';
import {addStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsEmploymentStatusStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    helperTitle: {
        id: 'consultations.DemographicsEmploymentStatusStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    helperLabel: {
        id: 'consultations.DemographicsEmploymentStatusStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperContent: {
        id: 'consultations.DemographicsEmploymentStatusStep.helperContent',
        defaultMessage: 'helperContent',
    },
});

/*
    Clinical:
    Determine whether the patient is working or studying, and how often.

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        helper: {
            title: props.intl.formatMessage(messages.helperTitle),
            label: props.intl.formatMessage(messages.helperLabel),
            content: props.intl.formatMessage(messages.helperContent),
        },
        title: props.intl.formatMessage(messages.questionTitle),
        answers: translateAnswers(employmentAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsEmploymentStatusStep,
    submitAction: submitDemographicsEmploymentStatusStep,
    undoAction: undoDemographicsEmploymentStatusStep,
});

export function reloadDemographicsEmploymentStatusStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) =>
                step.component.type === 'DemographicsEmploymentStatusStep',
        );
        if (previousStep) {
            const {employmentStatus} = getState().data.accounts.patient;
            return {
                answer: {
                    id: employmentStatus,
                },
            };
        }
    };
}

export function submitDemographicsEmploymentStatusStep(
    {stepId, answer},
    reload,
) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'employmentStatus',
                    toReplace: answer.id,
                }),
            );
        }
        if (employedStatuses.includes(answer.id)) {
            dispatch(
                addStep({
                    type: 'DemographicsOccupationStep',
                    parentId: stepId,
                }),
            );
        }
    };
}

export function undoDemographicsEmploymentStatusStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'employmentStatus',
                toReplace: null,
            }),
        );
    };
}
