import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {
    smokingAnswers,
    SMOKING_CURRENTLY,
    SMOKING_QUIT,
    translateAnswers,
} from 'clinical/helpers.js';
import {addSteps} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsSmokingStatusStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        answers: translateAnswers(smokingAnswers, props.intl),
    }),
    reloadAction: reloadSmokingStatus,
    submitAction: submitSmokingStatus,
    undoAction: undoSubmitSmokingStatus,
});

export function reloadSmokingStatus() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'DemographicsSmokingStatusStep',
        );
        if (previousStep) {
            const {smoking} = getState().data.accounts.patient;
            return {
                answer: {
                    id: smoking,
                },
            };
        }
    };
}

export function submitSmokingStatus({stepId, answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'smoking',
                    toReplace: answer.id,
                }),
            );
        }
        if (
            answer.id === SMOKING_QUIT.id ||
            answer.id === SMOKING_CURRENTLY.id
        ) {
            const steps = [
                {
                    type: 'DemographicsSmokingDurationStep',
                    props: {
                        smoking: answer.id,
                    },
                },
                {
                    type: 'DemographicsSmokingCountStep',
                    props: {
                        smoking: answer.id,
                    },
                },
            ];
            if (answer.id === 'QUIT') {
                steps.splice(1, 0, {
                    type: 'DemographicsSmokingQuitDurationStep',
                });
            }
            dispatch(addSteps({steps, parentId: stepId}));
        }
    };
}

export function undoSubmitSmokingStatus() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'smoking',
                toReplace: null,
            }),
        );
    };
}
