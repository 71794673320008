import {
    submitFeedback,
    undoFeedback,
} from 'consultations/actions/consultationActions.js';
import {createStep} from 'consultations/helpers.js';
import ChoiceQuestion from 'questions/components/ChoiceQuestion.js';
import {types} from 'questions/helpers.js';

/*
    Clinical:
    N/A

    Technical:
    This step is added before the Consultation submission and
    is added by default.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: ChoiceQuestion, // checkbox and radio only, not grouping
    reloadAction: reloadFeedbackQuestion,
    submitAction: submitFeedbackQuestion,
    undoAction: undoFeedbackQuestion,
});

export function reloadFeedbackQuestion(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const feedbackSteps = store.form.steps.filter(
            (step) => step.component.type === 'FeedbackQuestionStep',
        );
        const currentStepIndex = feedbackSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'FeedbackQuestionStep',
        );
        const previousStep = previousSteps[currentStepIndex];

        if (!previousStep) {
            return;
        }
        const currentStep = feedbackSteps[currentStepIndex];
        const {answers, qid, questionType} = currentStep.component.props;

        let {selectedAnswers} = store.data.accounts.feedbackQuestions[qid];

        if (!selectedAnswers) {
            return;
        }
        selectedAnswers = answers.filter((answer) =>
            selectedAnswers.includes(answer.id),
        );
        if (questionType === types.RADIO) {
            return {
                answer: selectedAnswers[0] || {id: null},
            };
        } else {
            return {
                answers: selectedAnswers,
            };
        }
    };
}

export function submitFeedbackQuestion({answers, answer, props}, reload) {
    return async function(dispatch) {
        if (!reload) {
            const qid = props.qid;
            const answerIds = [];
            if (answer && answer.id) {
                answerIds.push(answer.id);
            } else if (answers) {
                answers.forEach((answer) => {
                    if (answer.id) {
                        answerIds.push(answer.id);
                    }
                });
            }

            await dispatch(submitFeedback(qid, answerIds));
        }
    };
}

export function undoFeedbackQuestion({props}) {
    return async function(dispatch) {
        const {qid} = props;
        await dispatch(undoFeedback(qid));
    };
}
