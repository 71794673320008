import {defineMessages} from 'react-intl';

import {
    symptomTimeFrameAnswers,
    timeFrameMap,
    translateAnswers,
} from 'clinical/helpers.js';
import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

const messages = defineMessages({
    placeholder: {
        id: 'consultations.FrequencyStep.placeholder',
        defaultMessage: 'placeholder',
    },
    title: {
        id: 'consultations.FrequencyStep.title',
        defaultMessage: 'title',
    },
});

/*
    Clinical:
    How often is the patient experiencing the symptom?

    Technical:
    N/A

    Dependencies:
    Only shown if the patient chooses "comes and goes / occurred a few times"
    in the PatternStep

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    props: {minValue: 1},
    mapState: (store, props) => {
        let index = symptomTimeFrameAnswers.length;
        const timeFrame =
            store.data.consultations.consultationSymptoms[
                props.problem.consultationSymptomId
            ].firstOccurrenceTimeFrame;
        // max time frame should be the same as occurrence time frame
        // e.g. 3 weeks ago should have a max option of 'weeks'
        if (timeFrame) {
            const convertedTimeFrame = timeFrameMap[timeFrame];
            index =
                symptomTimeFrameAnswers.findIndex(
                    (stfa) => stfa.id === convertedTimeFrame,
                ) + 1;
        }
        return {
            options: translateAnswers(
                symptomTimeFrameAnswers.slice(0, index),
                props.intl,
            ),
            placeholder: props.intl.formatMessage(messages.placeholder),
            subTitle: props.problem.displayText,
            title: props.intl.formatMessage(messages.title),
        };
    },
    reloadAction: reloadFrequencyStep,
    submitAction: submitFrequency,
    undoAction: undoFrequency,
});

export function reloadFrequencyStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'FrequencyStep',
            stepId,
        });
        if (previousStep) {
            const {
                consultationSymptomId,
            } = previousStep.component.props.problem;
            const {
                numberOfOccurrences,
                numberOfOccurrencesTimeFrame,
                // eslint-disable-next-line max-len
            } = store.data.consultations.consultationSymptoms[
                consultationSymptomId
            ];
            return {
                answer: {
                    num: String(numberOfOccurrences),
                    option: numberOfOccurrencesTimeFrame,
                },
            };
        }
    };
}

export function submitFrequency({answer, props}, reload) {
    return async function(dispatch) {
        if (!reload) {
            const {consultationSymptomId} = props.problem;
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'numberOfOccurrences',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'numberOfOccurrencesTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoFrequency({props}) {
    return async function(dispatch) {
        const {consultationSymptomId} = props.problem;
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'numberOfOccurrences',
                toReplace: null,
            }),
        );
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'numberOfOccurrencesTimeFrame',
                toReplace: null,
            }),
        );
    };
}
