import moment from 'moment';
import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {
    demographicAgeAnswers,
    translateAnswers,
    DURATION_TIME_FRAME_YEARS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_WEEKS,
} from 'clinical/helpers';
import {addStepsForAge} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion';

export const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsAgeStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    placeholder: {
        id: 'consultations.DemographicsAgeStep.placeholder',
        defaultMessage: 'placeholder',
    },
    trailingText: {
        id: 'consultations.DemographicsAgeStep.trailingText',
        defaultMessage: 'trailingText',
    },
});

/*
    Clinical:
    This step is used to capture user's age. It is currently only for US.
    DOB step will not be shown in US hence this step is populated to get
    user age data.

    Technical:
    This step will only be populated if variable `mustComplyWithHipaa`
    in app is True. Age will be asked instead of DOB, and an estimate
    DOB is calculated based on the age input.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    props: {
        defaultOption: DURATION_TIME_FRAME_YEARS.id,
        maxValue: 100,
    },
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        placeholder: props.intl.formatMessage(messages.placeholder),
        trailingText: props.intl.formatMessage(messages.trailingText),
        options: translateAnswers(demographicAgeAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsAgeStep,
    submitAction: submitDemographicsAgeStep,
    undoAction: undoDemographicsAgeStep,
});

export function birthDateToAnswer(birthDate) {
    let num;
    let option;
    const months = moment().diff(birthDate, 'months');
    if (months < 1) {
        num = moment().diff(birthDate, 'weeks');
        option = DURATION_TIME_FRAME_WEEKS.id;
    } else if (months < 12) {
        num = months;
        option = DURATION_TIME_FRAME_MONTHS.id;
    } else {
        num = moment().diff(birthDate, 'years');
        option = DURATION_TIME_FRAME_YEARS.id;
    }
    return {option, num: num.toString()};
}

export function reloadDemographicsAgeStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'DemographicsAgeStep',
        );
        if (previousStep) {
            const {birthDate} = getState().data.accounts.patient;
            return {
                answer: birthDateToAnswer(birthDate),
            };
        }
    };
}

export function submitDemographicsAgeStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        const {isExistingPatient} = getState().data.consultations.consultation;
        const {num, option} = answer;
        const unit = demographicAgeAnswers.find((i) => i.id === option).unit;
        const birthDate = moment()
            .subtract(num, unit)
            .format('YYYY-MM-DD');
        const age = moment().diff(birthDate, 'years');
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'birthDate',
                    toReplace: birthDate,
                }),
            );
        }
        if (!isExistingPatient) {
            dispatch(addStepsForAge({stepId, age}));
        }
    };
}

export function undoDemographicsAgeStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'birthDate',
                toReplace: null,
            }),
        );
    };
}
