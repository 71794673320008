import moment from 'moment';
import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {addStepsForAge} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import DateQuestion from 'questions/components/DateQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsDateOfBirthStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    - Pre-populate DOB if available (either from PMS or existing BC patient)
*/

export default createStep({
    question: DateQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadDemographicsDateOfBirthStep,
    submitAction: submitDemographicsDateOfBirthStep,
    undoAction: undoDemographicsDateOfBirthStep,
});

export function reloadDemographicsDateOfBirthStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'DemographicsDateOfBirthStep',
        );
        if (previousStep) {
            const {birthDate} = getState().data.accounts.patient;
            return {
                answer: birthDate,
            };
        }
    };
}

export function submitDemographicsDateOfBirthStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        const {isExistingPatient} = getState().data.consultations.consultation;
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'birthDate',
                    toReplace: answer,
                }),
            );
        }

        if (!isExistingPatient) {
            const age = moment().diff(answer, 'years');
            dispatch(addStepsForAge({stepId, age}));
        }
    };
}

export function undoDemographicsDateOfBirthStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'birthDate',
                toReplace: null,
            }),
        );
    };
}
