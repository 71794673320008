import {defineMessages} from 'react-intl';

import {
    symptomEpisodeLengthAnswers,
    timeFrameMap,
    translateAnswers,
} from 'clinical/helpers.js';
import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

export const messages = defineMessages({
    title: {
        id: 'consultations.EpisodeLengthStep.title',
        defaultMessage: 'title',
    },
    placeholder: {
        id: 'consultations.EpisodeLengthStep.placeholder',
        defaultMessage: 'placeholder',
    },
    oneOffTitle: {
        id: 'consultations.EpisodeLengthStep.oneOffTitle',
        defaultMessage: 'oneOffTitle',
    },
});

/*
    Clinical:
    When the patient is experiencing their symptom (for symptoms that aren't
    always presenting), how long does it last for?

    Technical:
    N/A

    Dependencies:
    Is not inserted if the patient selects "Constant and Continuous" in the
    PatternStep

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    props: {minValue: 1},
    mapState: (store, props) => {
        let index = symptomEpisodeLengthAnswers.length;

        // eslint-disable-next-line max-len
        const consultationSymptom =
            store.data.consultations.consultationSymptoms[
                props.problem.consultationSymptomId
            ];
        const freqTimeFrame = consultationSymptom.numberOfOccurrencesTimeFrame;
        // eslint-disable-next-line max-len
        const firstOccurrenceTimeFrame =
            consultationSymptom.firstOccurrenceTimeFrame;

        if (freqTimeFrame) {
            // max time frame should be shorter than frequency time frame
            // e.g. 3 times per week should have a max option of 'days'
            const convertedTimeFrame = timeFrameMap[freqTimeFrame];
            index = symptomEpisodeLengthAnswers.findIndex(
                (stfa) => stfa.id === convertedTimeFrame,
            );
        } else if (firstOccurrenceTimeFrame) {
            index = symptomEpisodeLengthAnswers.findIndex(
                (stfa) => stfa.id === firstOccurrenceTimeFrame,
            );
            index++;
        }

        return {
            options: translateAnswers(
                symptomEpisodeLengthAnswers.slice(0, index),
                props.intl,
            ),
            placeholder: props.intl.formatMessage(messages.placeholder),
            subTitle: props.problem.displayText,
            title: props.isOneOff
                ? props.intl.formatMessage(messages.oneOffTitle)
                : props.intl.formatMessage(messages.title),
        };
    },
    reloadAction: reloadEpisodeLengthStep,
    submitAction: submitEpisodeLengthStep,
    undoAction: undoEpisodeLengthStep,
});

export function reloadEpisodeLengthStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'EpisodeLengthStep',
            stepId,
        });
        if (previousStep) {
            const {
                consultationSymptomId,
            } = previousStep.component.props.problem;
            const {
                episodeLength,
                episodeLengthTimeFrame,
                // eslint-disable-next-line max-len
            } = store.data.consultations.consultationSymptoms[
                consultationSymptomId
            ];
            return {
                answer: {
                    num: String(episodeLength),
                    option: episodeLengthTimeFrame,
                },
            };
        }
    };
}

export function submitEpisodeLengthStep({props, answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            const {problem} = props;
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'episodeLength',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'episodeLengthTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoEpisodeLengthStep({props}) {
    return async function(dispatch) {
        const {problem} = props;
        await dispatch(
            updateConsultationSymptom(problem.consultationSymptomId, {
                field: 'episodeLength',
                toReplace: null,
            }),
        );
        await dispatch(
            updateConsultationSymptom(problem.consultationSymptomId, {
                field: 'episodeLengthTimeFrame',
                toReplace: null,
            }),
        );
    };
}
