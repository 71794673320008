import {defineMessages} from 'react-intl';
import {updatePatient} from 'accounts/actions/accountsActions.js';
import {createStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

export const messages = defineMessages({
    quitTitle: {
        id: 'consultations.DemographicsSmokingCountStep.quitTitle',
        defaultMessage: 'quitTitle',
    },
    cusmTitle: {
        id: 'consultations.DemographicsSmokingCountStep.cusmTitle',
        defaultMessage: 'cusmTitle',
    },
    placeholder: {
        id: 'consultations.DemographicsSmokingCountStep.placeholder',
        defaultMessage: 'placeholder',
    },
});

/*
    Clinical:
    N/A

    Technical:
    Number of cigarettes consumed per day, has a past tense title if the user
    has already quit.

    Dependencies:
    Only shown if the user says they smoke in DemographicsSmokingStatusStep

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    mapState: (store, props) => ({
        placeholder: props.intl.formatMessage(messages.placeholder),
        title: props.intl.formatMessage(
            messages[`${props.smoking.toLowerCase()}Title`],
        ),
    }),
    reloadAction: reloadDemographicsSmokingCount,
    submitAction: submitDemographicsSmokingCount,
    undoAction: undoDemographicsSmokingCount,
});

export function reloadDemographicsSmokingCount() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'DemographicsSmokingCountStep',
        );
        if (previousStep) {
            const {numberOfCigarettes} = getState().data.accounts.patient;
            return {
                answer: {
                    num: numberOfCigarettes,
                },
            };
        }
    };
}

export function submitDemographicsSmokingCount({answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'numberOfCigarettes',
                    toReplace: answer.num,
                }),
            );
        }
    };
}

export function undoDemographicsSmokingCount() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'numberOfCigarettes',
                toReplace: null,
            }),
        );
    };
}
