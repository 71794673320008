import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {sexAnswers, translateAnswers} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    helperContent: {
        id: 'consultations.DemographicsSexStep.helperContent',
        defaultMessage: 'helperContent',
    },
    helperLabel: {
        id: 'consultations.DemographicsSexStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperTitle: {
        id: 'consultations.DemographicsSexStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    questionTitle: {
        id: 'consultations.DemographicsSexStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    - Pre-populate from either existing BC data or PMS
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        answers: translateAnswers(sexAnswers, props.intl),
        helper: {
            content: props.intl.formatMessage(messages.helperContent),
            label: props.intl.formatMessage(messages.helperLabel),
            title: props.intl.formatMessage(messages.helperTitle),
        },
    }),
    reloadAction: reloadDemographicsSexStep,
    submitAction: submitDemographicsSexStep,
    undoAction: undoDemographicsSexStep,
});

export function reloadDemographicsSexStep() {
    return function (dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'DemographicsSexStep',
        );
        if (previousStep) {
            const {sex} = getState().data.accounts.patient;
            return {
                answer: {
                    id: sex,
                },
            };
        }
    };
}

export function submitDemographicsSexStep({answer}, reload) {
    return async function (dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'sex',
                    toReplace: answer.id,
                }),
            );
        }
    };
}

export function undoDemographicsSexStep() {
    return async function (dispatch) {
        await dispatch(
            updatePatient({
                field: 'sex',
                toReplace: null,
            }),
        );
    };
}
