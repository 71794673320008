import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {
    demographicDurationAnswers,
    translateAnswers,
} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsSmokingQuitDurationStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    placeholder: {
        id: 'consultations.DemographicsSmokingQuitDurationStep.placeholder',
        defaultMessage: 'placeholder',
    },
    trailingText: {
        id: 'consultations.DemographicsSmokingQuitDurationStep.trailingText',
        defaultMessage: 'trailingText',
    },
});

/*
    Clinical:
    How long ago did the patient quit smoking?

    Technical:
    N/A

    Dependencies:
    Only shown if the user says they quit smoking in
    DemographicsSmokingStatusStep

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    mapState: (store, props) => ({
        placeholder: props.intl.formatMessage(messages.placeholder),
        title: props.intl.formatMessage(messages.questionTitle),
        trailingText: props.intl.formatMessage(messages.trailingText),
        options: translateAnswers(demographicDurationAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsSmokingQuitDuration,
    submitAction: submitDemographicsSmokingQuitDuration,
    undoAction: undoDemographicsSmokingQuitDuration,
});

export function reloadDemographicsSmokingQuitDuration() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) =>
                step.component.type === 'DemographicsSmokingQuitDurationStep',
        );
        if (previousStep) {
            const {
                whenQuit,
                whenQuitTimeFrame,
            } = getState().data.accounts.patient;
            return {
                answer: {
                    num: whenQuit.toString(),
                    option: whenQuitTimeFrame,
                },
            };
        }
    };
}

export function submitDemographicsSmokingQuitDuration({answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'whenQuit',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updatePatient({
                    field: 'whenQuitTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoDemographicsSmokingQuitDuration() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'whenQuit',
                toReplace: null,
            }),
        );
        await dispatch(
            updatePatient({
                field: 'whenQuitTimeFrame',
                toReplace: null,
            }),
        );
    };
}
