import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.ExacerbatingFactorsStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    Things that make the symptom worse.

    Technical:
    Only shown if there are exacerbating factors on the Symptom

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        subTitle: props.problem.displayText,
    }),
    reloadAction: reloadExacerbatingFactorsStep,
    submitAction: submitExacerbatingFactorsStep,
    undoAction: undoExacerbatingFactorsStep,
});

export function reloadExacerbatingFactorsStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const currentStep = store.form.steps.find(
            (step) =>
                step.component.type === 'ExacerbatingFactorsStep' &&
                step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'ExacerbatingFactorsStep',
        );
        const {problem} = currentStep.component.props;
        const previousStep = previousSteps.find(
            (step) =>
                step.component.props.problem.symptomId === problem.symptomId,
        );
        if (previousStep) {
            const {answers, problem} = previousStep.component.props;
            const factors =
                store.data.consultations.consultationSymptoms[
                    problem.consultationSymptomId
                ].exacerbatingFactors;
            if (factors) {
                const selectedAnswers = answers.filter((answer) =>
                    factors.some((factor) => answer.id === factor.id),
                );
                return {
                    answers: selectedAnswers,
                };
            }
        }
    };
}

export function submitExacerbatingFactorsStep({props, answers}, reload) {
    return async function(dispatch) {
        if (!reload && answers.length) {
            const {problem} = props;
            const toAdd = answers.map((answer) => answer.id);
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'exacerbatingFactors',
                    toAdd,
                }),
            );
        }
    };
}

export function undoExacerbatingFactorsStep({props, answers}) {
    return async function(dispatch) {
        const {problem} = props;

        if (answers.length) {
            const toRemove = answers.map((answer) => answer.id);
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'exacerbatingFactors',
                    toRemove,
                }),
            );
        }
    };
}
