import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {careEnvironmentAnswers, translateAnswers} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    title: {
        id: 'consultations.DemographicsCareEnvironmentStep.title',
        defaultMessage: 'title',
    },
});

/*
    Clinical:
    What are the relevant care environments?

    Technical:
    N/A

    Dependencies:
    Only shown if the patient is under 5

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    props: {
        required: true,
    },
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.title),
        answers: translateAnswers(careEnvironmentAnswers, props.intl),
    }),
    reloadAction: reloadCareEnvironmentStep,
    submitAction: submitCareEnvironmentStep,
    undoAction: undoCareEnvironmentStep,
});

export function reloadCareEnvironmentStep() {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = store.form.previousSteps.find(
            (step) =>
                step.component.type === 'DemographicsCareEnvironmentStep',
        );
        const {careEnvironments} = store.data.accounts.patient;
        if (previousStep && careEnvironments) {
            return {
                answers: careEnvironments.map((id) => ({id})),
            };
        }
    };
}

export function submitCareEnvironmentStep({answers}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'careEnvironments',
                    toReplace: answers.map((item) => item.id),
                }),
            );
        }
    };
}

export function undoCareEnvironmentStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'careEnvironments',
                toReplace: null,
            }),
        );
    };
}
