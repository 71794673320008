import {defineMessages} from 'react-intl';

import {
    firstOccurrenceTimeFrameAnswers,
    translateAnswers,
} from 'clinical/helpers.js';
import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

export const messages = defineMessages({
    title: {
        id: 'consultations.FirstOccurrenceStep.title',
        defaultMessage: 'title',
    },
    placeholder: {
        id: 'consultations.FirstOccurrenceStep.placeholder',
        defaultMessage: 'placeholder',
    },
    trailingText: {
        id: 'consultations.FirstOccurrenceStep.trailingText',
        defaultMessage: 'trailingText',
    },
});

/*
    Clinical:
    When did the symptom first happen?

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    props: {
        minValue: 0,
    },
    mapState: (store, props) => ({
        placeholder: props.intl.formatMessage(messages.placeholder),
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.title),
        trailingText: props.intl.formatMessage(messages.trailingText),
        options: translateAnswers(firstOccurrenceTimeFrameAnswers, props.intl),
    }),
    reloadAction: reloadFirstOccurrenceStep,
    submitAction: submitFirstOccurrenceStep,
    undoAction: undoFirstOccurrenceStep,
});

export function reloadFirstOccurrenceStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const currentSteps = store.form.steps.filter(
            (step) => step.component.type === 'FirstOccurrenceStep',
        );
        const currentStepIndex = currentSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'FirstOccurrenceStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (!previousStep) {
            return;
        }
        const currentStep = currentSteps[currentStepIndex];
        const {problem} = currentStep.component.props;
        const {consultationSymptomId} = problem;
        if (
            consultationSymptomId !==
            previousStep.component.props.problem.consultationSymptomId
        ) {
            return;
        }
        // eslint-disable-next-line max-len
        const consultationSymptom =
            store.data.consultations.consultationSymptoms[
                consultationSymptomId
            ];
        if (
            !consultationSymptom ||
            Number.isNaN(parseInt(consultationSymptom.firstOccurrence)) ||
            !consultationSymptom.firstOccurrenceTimeFrame
        ) {
            return;
        }
        return {
            answer: {
                num: consultationSymptom.firstOccurrence.toString(),
                option: consultationSymptom.firstOccurrenceTimeFrame,
            },
        };
    };
}

export function submitFirstOccurrenceStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const store = getState();
            const step = store.form.steps.find((step) => step.id === stepId);
            const {problem} = step.component.props;
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'firstOccurrence',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'firstOccurrenceTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoFirstOccurrenceStep({stepId}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        await dispatch(
            updateConsultationSymptom(problem.consultationSymptomId, {
                field: 'firstOccurrence',
                toReplace: null,
            }),
        );
        await dispatch(
            updateConsultationSymptom(problem.consultationSymptomId, {
                field: 'firstOccurrenceTimeFrame',
                toReplace: null,
            }),
        );
    };
}
