import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {
    demographicTimeFrameAnswers,
    translateAnswers,
} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsAlcoholQuantityStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    helperTitle: {
        id: 'consultations.DemographicsAlcoholQuantityStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    helperLabel: {
        id: 'consultations.DemographicsAlcoholQuantityStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperContent: {
        id: 'consultations.DemographicsAlcoholQuantityStep.helperContent',
        defaultMessage: 'helperContent',
    },
    placeholder: {
        id: 'consultations.DemographicsAlcoholQuantityStep.placeholder',
        defaultMessage: 'placeholder',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    Step is only shown if patient selects something other than "Never" in
    DemographicsAlcoholFrequencyStep.

    Future:
    - N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    mapState: (store, props) => ({
        helper: {
            title: props.intl.formatMessage(messages.helperTitle),
            label: props.intl.formatMessage(messages.helperLabel),
            content: props.intl.formatMessage(messages.helperContent),
        },
        title: props.intl.formatMessage(messages.questionTitle),
        placeholder: props.intl.formatMessage(messages.placeholder),
        options: translateAnswers(demographicTimeFrameAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsAlcoholQuantityStep,
    submitAction: submitDemographicsAlcoholQuantityStep,
    undoAction: undoDemographicsAlcoholQuantityStep,
});

export function reloadDemographicsAlcoholQuantityStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) =>
                step.component.type === 'DemographicsAlcoholQuantityStep',
        );
        if (previousStep) {
            const {
                numberOfDrinks,
                numberOfDrinksTimeFrame,
            } = getState().data.accounts.patient;
            return {
                answer: {
                    num: numberOfDrinks,
                    option: numberOfDrinksTimeFrame,
                },
            };
        }
    };
}

export function submitDemographicsAlcoholQuantityStep({answer}, reload) {
    return async function(dispatch) {
        // TODO: improve updatePatient to be able to take in an array of
        // fields that we want to update
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'numberOfDrinks',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updatePatient({
                    field: 'numberOfDrinksTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoDemographicsAlcoholQuantityStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'numberOfDrinks',
                toReplace: null,
            }),
        );
        await dispatch(
            updatePatient({
                field: 'numberOfDrinksTimeFrame',
                toReplace: null,
            }),
        );
    };
}
