import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {maritalStatusAnswers, translateAnswers} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsMaritalStatusStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    helperTitle: {
        id: 'consultations.DemographicsMaritalStatusStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    helperLabel: {
        id: 'consultations.DemographicsMaritalStatusStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperContent: {
        id: 'consultations.DemographicsMaritalStatusStep.helperContent',
        defaultMessage: 'helperContent',
    },
});

/*
    Clinical:
    This is relevant for specialities involving mental health.

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    - Only ask when relevant, talk to Ron.
*/

export default createStep({
    question: RadioQuestion,
    props: {
        capitalisation: 'title',
    },
    mapState: (store, props) => ({
        helper: {
            title: props.intl.formatMessage(messages.helperTitle),
            label: props.intl.formatMessage(messages.helperLabel),
            content: props.intl.formatMessage(messages.helperContent),
        },
        title: props.intl.formatMessage(messages.questionTitle),
        answers: translateAnswers(maritalStatusAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsMaritalStatusStep,
    submitAction: submitDemographicsMaritalStatusStep,
    undoAction: undoDemographicsMaritalStatusStep,
});

export function reloadDemographicsMaritalStatusStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'DemographicsMaritalStatusStep',
        );
        if (previousStep) {
            const {maritalStatus} = getState().data.accounts.patient;
            return {
                answer: {
                    id: maritalStatus,
                },
            };
        }
    };
}

export function submitDemographicsMaritalStatusStep({answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'maritalStatus',
                    toReplace: answer.id,
                }),
            );
        }
    };
}

export function undoDemographicsMaritalStatusStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'maritalStatus',
                toReplace: null,
            }),
        );
    };
}
