import {defineMessages} from 'react-intl';

import {createPatientRecreationalDrugs} from 'accounts/actions/accountsActions.js';
import {DRUGS_NOT_ANYMORE} from 'clinical/helpers.js';
import {addSteps} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    questionTitle: {
        // eslint-disable-next-line max-len
        id:
            'consultations.DemographicsRecreationalDrugChoicesStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    Only added if the user says they consume recreational drugs in the
    DemographicsRecreationalDrugStatusStep.

    Future:
    - This is currently a list of drugs that I created with no real thought
      to how common they are. This should be expanded in the future and
      allow free-text.
*/

export default createStep({
    question: CheckboxQuestion,
    mapState: (store, props) => ({
        // eslint-disable-next-line max-len
        answers: Object.values(store.data.clinical.recreationalDrugs).map(
            (d) => ({
                id: d.id,
                text: d.name,
            }),
        ),
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadDemographicsRecreationalDrugChoicesStep,
    submitAction: submitDemographicsRecreationalDrugChoicesStep,
    undoAction: undoDemographicsRecreationalDrugChoicesStep,
});

export function reloadDemographicsRecreationalDrugChoicesStep() {
    return function (dispatch, getState) {
        const store = getState();
        const previousStep = store.form.previousSteps.find(
            (step) =>
                step.component.type ===
                'DemographicsRecreationalDrugChoicesStep',
        );
        if (previousStep) {
            const {patientRecreationalDrugs} = store.data.accounts;
            const {recreationalDrugs} = store.data.clinical;
            return {
                answers: Object.values(patientRecreationalDrugs)
                    .filter((pd) => recreationalDrugs.hasOwnProperty(pd.drug))
                    .map((pd) => {
                        const recreationalDrug = recreationalDrugs[pd.drug];
                        return {
                            id: recreationalDrug.id,
                            text: recreationalDrug.name,
                        };
                    }),
            };
        }
    };
}

export function submitDemographicsRecreationalDrugChoicesStep(
    {stepId, answers},
    reload,
) {
    return async function (dispatch, getState) {
        if (!reload) {
            await dispatch(
                createPatientRecreationalDrugs(
                    answers.map((answer) => answer.id),
                ),
            );
        }
        const drugs = getState().data.accounts.patientRecreationalDrugs;
        // eslint-disable-next-line max-len
        const previouslyUsed =
            getState().data.accounts.patient.recDrugFrequency ===
            DRUGS_NOT_ANYMORE.id;
        const steps = [];
        for (const drug of Object.values(drugs)) {
            const drugAnswer = answers.find(
                (answer) => answer.id === drug.drug,
            );
            const drugProps = {
                text: drugAnswer && drugAnswer.text,
                id: drug.id,
                drug: drug.drug,
            };
            steps.push({
                type: 'DemographicsRecreationalDrugIndividualFrequencyStep',
                props: {drug: drugProps, previouslyUsed},
            });
        }
        dispatch(
            addSteps({
                steps,
                parentId: stepId,
            }),
        );
    };
}

export function undoDemographicsRecreationalDrugChoicesStep() {
    return async function (dispatch) {
        await dispatch(createPatientRecreationalDrugs([]));
    };
}
