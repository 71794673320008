import {defineMessages} from 'react-intl';

import {durationTimeFrameAnswers, translateAnswers} from 'clinical/helpers.js';
import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

const messages = defineMessages({
    title: {
        id: 'consultations.DurationStep.title',
        defaultMessage: 'title',
    },
    placeholder: {
        id: 'consultations.DurationStep.placeholder',
        defaultMessage: 'placeholder',
    },
});

/*
    Clinical:
    For how long has the patient had this symptom?

    Technical:
    Only shown if has_duration=True on Symptom.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    props: {
        decimalDigits: 1,
        maxValue: 99999.9,
    },
    mapState: (store, props) => ({
        placeholder: props.intl.formatMessage(messages.placeholder),
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.title),
        options: translateAnswers(durationTimeFrameAnswers, props.intl),
    }),
    reloadAction: reloadDurationStep,
    submitAction: submitDurationStep,
    undoAction: undoDurationStep,
});

export function reloadDurationStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const durationSteps = store.form.steps.filter(
            (step) => step.component.type === 'DurationStep',
        );
        const currentStepIndex = durationSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'DurationStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (!previousStep) {
            return;
        }
        const {consultationSymptomId} = previousStep.component.props.problem;
        const {
            duration,
            durationTimeFrame,
        } = store.data.consultations.consultationSymptoms[
            consultationSymptomId
        ];
        return {
            answer: {
                num: String(parseFloat(duration)),
                option: durationTimeFrame,
            },
        };
    };
}

export function submitDurationStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        // TODO: improve updateConsultationSymptom to be able to take in an
        // array of fields that we want to update
        if (!reload) {
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'duration',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'durationTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoDurationStep({stepId}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {consultationSymptomId} = step.component.props.problem;
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'duration',
                toReplace: null,
            }),
        );
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'durationTimeFrame',
                toReplace: null,
            }),
        );
    };
}
