import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {
    drinkingFrequencyAnswers,
    DRINKING_NEVER,
    translateAnswers,
} from 'clinical/helpers.js';
import {addStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.DemographicsAlcoholFrequencyStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    Does the patient consume alcohol? Only ask patients over the age of 12.

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        answers: translateAnswers(drinkingFrequencyAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsAlcoholFrequencyStep,
    submitAction: submitDemographicsAlcoholFrequencyStep,
    undoAction: undoDemographicsAlcoholFrequencyStep,
});

export function reloadDemographicsAlcoholFrequencyStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) =>
                step.component.type === 'DemographicsAlcoholFrequencyStep',
        );
        if (previousStep) {
            const {drinkingFrequency} = getState().data.accounts.patient;
            return {
                answer: {
                    id: drinkingFrequency,
                },
            };
        }
    };
}

// eslint-disable-next-line max-len
export function submitDemographicsAlcoholFrequencyStep(
    {stepId, answer},
    reload,
) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'drinkingFrequency',
                    toReplace: answer.id,
                }),
            );
        }
        if (answer.id !== DRINKING_NEVER.id) {
            dispatch(
                addStep({
                    type: 'DemographicsAlcoholQuantityStep',
                    parentId: stepId,
                }),
            );
        }
    };
}

export function undoDemographicsAlcoholFrequencyStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'drinkingFrequency',
                toReplace: null,
            }),
        );
    };
}
