import {defineMessages} from 'react-intl';

import http from 'http.js';

import {
    CLINICAL_CONDITIONS_SEARCH_API,
    CONSULTATIONS_CONSULTATIONS_API,
} from 'apiEndpoints.js';
import autocompleteManager from 'autocomplete_manager.js';
import actions from 'better_consult/actions/types.js';
import {addSteps, setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import deduplicate from 'utils/deduplicate.js';

export const messages = defineMessages({
    additionalAnswerText: {
        id: 'consultations.FamilyConditionsStep.additionalAnswerText',
        defaultMessage: 'additionalAnswerText',
    },
    disabledResultText: {
        id: 'consultations.FamilyConditionsStep.disabledResultText',
        defaultMessage: 'disabledResultText',
    },
    noConditionsQuestionTitle: {
        id: 'consultations.FamilyConditionsStep.noConditionsQuestionTitle',
        defaultMessage: 'noConditionsQuestionTitle',
    },
    noConditionsSearchPlaceholder: {
        id: 'consultations.FamilyConditionsStep.noConditionsSearchPlaceholder',
        defaultMessage: 'noConditionsSearchPlaceholder',
    },
    questionTitle: {
        id: 'consultations.FamilyConditionsStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    searchPlaceholder: {
        id: 'consultations.FamilyConditionsStep.searchPlaceholder',
        defaultMessage: 'searchPlaceholder',
    },
});

/*
    Clinical:
    N/A

    Technical:
    Every response will generate a FamilyConditionRelationshipStep

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    props: {
        searchAction: searchConditions,
    },
    mapState: (store, props) => {
        const cSymptoms = Object.values(
            store.data.consultations.consultationSymptoms,
        );
        const symptoms = store.data.clinical.symptoms;
        let familyConditions = Object.values(
            store.data.clinical.conditions,
        ).filter((condition) => condition.isHistoryCondition);
        for (const cSymptom of cSymptoms) {
            if (cSymptom.symptomId) {
                const symptom = symptoms[cSymptom.symptomId];
                familyConditions.push(...symptom.familyConditions);
            }
        }
        const consultationFConditions = Object.values(
            store.data.consultations.familyConditions,
        ).map((fCon) => fCon.condition);
        familyConditions.push(...consultationFConditions);
        familyConditions = deduplicate(familyConditions, (c) => c.id);
        const title = familyConditions.length
            ? props.intl.formatMessage(messages.questionTitle)
            : props.intl.formatMessage(messages.noConditionsQuestionTitle);
        return {
            answers: familyConditions.map((item) => ({
                id: item.id,
                text: item.name,
            })),
            disabledResultText: props.intl.formatMessage(
                messages.disabledResultText,
            ),
            searchPlaceholder: props.intl.formatMessage(
                messages.searchPlaceholder,
            ),
            title,
        };
    },
    reloadAction: reloadFamilyConditionsStep,
    submitAction: submitFamilyConditionStep,
    undoAction: undoFamilyConditionStep,
});

export async function searchConditions(q) {
    let results = [];
    if (q) {
        const url = CLINICAL_CONDITIONS_SEARCH_API;
        try {
            results = await autocompleteManager.getResults(url, {
                q,
                filter: 'past-condition',
            });
            results = results.map((result) => ({
                id: result.id,
                text: result.name,
                type: result.type,
                synonyms: result.synonyms || [],
            }));
        } catch (err) {
            throw err;
        }
    }
    return results;
}

export function reloadFamilyConditionsStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'FamilyConditionsStep',
        );
        if (!previousStep) {
            return;
        }
        const answers = [];
        const {familyConditions} = store.data.consultations;
        for (const con of Object.values(familyConditions)) {
            answers.push({
                id: con.condition.id,
                text: con.condition.name,
                type: con.condition.type,
                synonyms: con.condition.synonyms || [],
            });
        }
        dispatch(
            setDataOnStep({
                stepId,
                field: 'familyConditions',
                value: previousStep.component.props.familyConditions,
            }),
        );
        return {
            answers,
        };
    };
}

export function submitFamilyConditionStep({stepId, answers}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            let response;
            const {id} = getState().data.consultations.consultation;
            response = await http.post({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/family-conditions/`,
                data: {
                    // eslint-disable-next-line camelcase
                    family_conditions: answers.map((item) => ({
                        condition: item.id,
                    })),
                },
            });
            if (!response) {
                return;
            }
            response = response.map(snakeToCamelObjectKeys);
            dispatch({
                type: actions.data.ADD_FAMILY_CONDITIONS,
                payload: response,
            });
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'familyConditions',
                    value: response.map((item) => item.id),
                }),
            );
        }
        const {familyConditions} = getState().data.consultations;
        const famCons = Object.values(familyConditions);
        if (famCons.length) {
            const steps = famCons.map((item) => ({
                type: 'FamilyConditionRelationshipStep',
                props: {
                    name: item.condition.name,
                    fcid: item.id,
                },
            }));
            dispatch(
                addSteps({
                    steps,
                    parentId: stepId,
                }),
            );
        }
    };
}

export function undoFamilyConditionStep({stepId}) {
    return async function(dispatch, getState) {
        const step = getState().form.steps.find((step) => step.id === stepId);
        const {familyConditions} = step.component.props;
        const {id} = getState().data.consultations.consultation;
        try {
            await http.delete({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/family-conditions/`,
                data: {id: familyConditions},
            });
        } catch (err) {
            throw err;
        }
        dispatch({
            type: actions.data.REMOVE_FAMILY_CONDITIONS,
            payload: {ids: familyConditions},
        });
        dispatch(
            setDataOnStep({
                stepId,
                field: 'familyConditions',
                value: undefined,
            }),
        );
    };
}
