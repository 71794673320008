import {defineMessages} from 'react-intl';

import {CONSULTATIONS_CONSULTATIONS_API} from 'apiEndpoints.js';
import actions from 'better_consult/actions/types.js';
import {createStep} from 'consultations/helpers.js';
import http from 'http.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.FamilyConditionRelationshipStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

const RELATIONSHIP_FATHER = {
    id: 'FA',
    text: 'Father',
};

const RELATIONSHIP_MOTHER = {
    id: 'MO',
    text: 'Mother',
};

const RELATIONSHIP_BROTHER = {
    id: 'BR',
    text: 'Brother',
};

const RELATIONSHIP_SISTER = {
    id: 'SI',
    text: 'Sister',
};

const RELATIONSHIP_PATERNAL_GRANDFATHER = {
    id: 'PGF',
    text: 'Paternal Grandfather',
};

const RELATIONSHIP_MATERNAL_GRANDFATHER = {
    id: 'MGF',
    text: 'Maternal Grandfather',
};

const RELATIONSHIP_PATERNAL_GRANDMOTHER = {
    id: 'PGM',
    text: 'Paternal Grandmother',
};

const RELATIONSHIP_MATERNAL_GRANDMOTHER = {
    id: 'MGM',
    text: 'Maternal Grandmother',
};

const RELATIONSHIP_FIRST_COUSIN = {
    id: 'FC',
    text: 'First Cousin',
};

const RELATIONSHIP_MOTHER_SIBLING = {
    id: 'MS',
    text: "Mother's Sibling",
};

const RELATIONSHIP_FATHER_SIBLING = {
    id: 'FS',
    text: "Father's Sibling",
};

export const familyRelationshipAnswers = [
    RELATIONSHIP_FATHER,
    RELATIONSHIP_MOTHER,
    RELATIONSHIP_BROTHER,
    RELATIONSHIP_SISTER,
    RELATIONSHIP_PATERNAL_GRANDFATHER,
    RELATIONSHIP_MATERNAL_GRANDFATHER,
    RELATIONSHIP_PATERNAL_GRANDMOTHER,
    RELATIONSHIP_MATERNAL_GRANDMOTHER,
    RELATIONSHIP_FIRST_COUSIN,
    RELATIONSHIP_MOTHER_SIBLING,
    RELATIONSHIP_FATHER_SIBLING,
];

/*
    Clinical:
    Which relative had a condition can be relevant for the doctor (e.g. if
    patient is male and father has heart disease, more likely to contract
    heart disease)

    Technical:
    N/A

    Dependencies:
    Only shown if the patient selects conditions from the FamilyConditionsStep

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    props: {
        answers: familyRelationshipAnswers,
    },
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle, {
            familyCondition: props.name,
        }),
    }),
    reloadAction: reloadFamilyConditionRelationship,
    submitAction: submitFamilyConditionRelationship,
    undoAction: undoFamilyConditionRelationship,
});

export function reloadFamilyConditionRelationship(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousSteps = store.form.previousSteps.filter(
            (step) =>
                step.component.type === 'FamilyConditionRelationshipStep',
        );
        const currentStepIndex = store.form.steps
            .filter(
                (step) =>
                    step.component.type === 'FamilyConditionRelationshipStep',
            )
            .findIndex((step) => step.id === stepId);
        const previousStep = previousSteps[currentStepIndex];
        if (!previousStep) {
            return;
        }
        const familyConditions = store.data.consultations.familyConditions;
        const famCon = familyConditions[previousStep.component.props.fcid];
        const answers = famCon.relationships.map((r) => ({id: r}));
        return {
            answers,
        };
    };
}

export function submitFamilyConditionRelationship({props, answers}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const {fcid} = props;
            const {id} = getState().data.consultations.consultation;
            const response = await http.patch({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/family-conditions/${fcid}/`,
                data: {
                    relationships: answers.map((item) => item.id),
                },
            });
            dispatch({
                type: actions.data.SET_FAMILY_CONDITION_ATTR,
                payload: {
                    fcid,
                    field: 'relationships',
                    value: response.value,
                },
            });
        }
    };
}

export function undoFamilyConditionRelationship({props}) {
    return async function(dispatch, getState) {
        const {fcid} = props;
        const {id} = getState().data.consultations.consultation;
        const response = await http.patch({
            // eslint-disable-next-line max-len
            url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/family-conditions/${fcid}/`,
            data: {relationships: []},
        });
        dispatch({
            type: actions.data.SET_FAMILY_CONDITION_ATTR,
            payload: {
                fcid,
                field: 'relationships',
                value: response.value,
            },
        });
    };
}
