import {defineMessages} from 'react-intl';

import {updatePatientRecreationalDrug} from 'accounts/actions/accountsActions.js';
import {drugUsageAnswers, translateAnswers} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

export const messages = defineMessages({
    questionTitle: {
        // eslint-disable-next-line max-len
        id:
            'consultations.DemographicsRecreationalDrugIndividualFrequencyStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    previouslyUsedQuestionTitle: {
        // eslint-disable-next-line max-len
        id:
            'consultations.DemographicsRecreationalDrugIndividualFrequencyStep.previouslyUsedQuestionTitle',
        defaultMessage: 'previouslyUsedQuestionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    Is shown once per drug selected in DemographicsRecreationalDrugChoicesStep
 */

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(
            // eslint-disable-next-line max-len
            props.previouslyUsed
                ? messages.previouslyUsedQuestionTitle
                : messages.questionTitle,
            {drug: props.drug.text},
        ),
        answers: translateAnswers(drugUsageAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsRecreationalDrugIndividualFrequencyStep,
    submitAction: submitDemographicsRecreationalDrugIndividualFrequencyStep,
    undoAction: undoDemographicsRecreationalDrugIndividualFrequencyStep,
});

// eslint-disable-next-line max-len
export function reloadDemographicsRecreationalDrugIndividualFrequencyStep(
    stepId,
) {
    return function(dispatch, getState) {
        const store = getState();
        const currentStepIndex = store.form.steps
            .filter(
                (step) =>
                    step.component.type ===
                    'DemographicsRecreationalDrugIndividualFrequencyStep',
            )
            .findIndex((step) => step.id === stepId);
        const previousSteps = store.form.previousSteps.filter(
            (step) =>
                step.component.type ===
                'DemographicsRecreationalDrugIndividualFrequencyStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (!previousStep) {
            return;
        }
        const {patientRecreationalDrugs} = store.data.accounts;
        const frequency =
            patientRecreationalDrugs[previousStep.component.props.drug.id]
                .frequency;
        return {
            answer: {
                id: frequency,
            },
        };
    };
}

// eslint-disable-next-line max-len
export function submitDemographicsRecreationalDrugIndividualFrequencyStep(
    {props, answer},
    reload,
) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatientRecreationalDrug(props.drug.id, {
                    field: 'frequency',
                    toReplace: answer.id,
                }),
            );
        }
    };
}

// eslint-disable-next-line max-len
export function undoDemographicsRecreationalDrugIndividualFrequencyStep({
    props,
}) {
    return async function(dispatch) {
        await dispatch(
            updatePatientRecreationalDrug(props.drug.id, {
                field: 'frequency',
                toReplace: null,
            }),
        );
    };
}
