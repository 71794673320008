import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {createStep} from 'consultations/helpers.js';
import TextQuestion from 'questions/components/TextQuestion.js';

const messages = defineMessages({
    helperContent: {
        id: 'consultations.DemographicsOccupationStep.helperContent',
        defaultMessage: 'helperContent',
    },
    helperLabel: {
        id: 'consultations.DemographicsOccupationStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperTitle: {
        id: 'consultations.DemographicsOccupationStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    placeholder: {
        id: 'consultations.DemographicsOccupationStep.placeholder',
        defaultMessage: 'placeholder',
    },
    questionTitle: {
        id: 'consultations.DemographicsOccupationStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    - Pre-populate from either existing BC data or PMS
*/

export default createStep({
    question: TextQuestion,
    mapState: (store, props) => ({
        helper: {
            title: props.intl.formatMessage(messages.helperTitle),
            label: props.intl.formatMessage(messages.helperLabel),
            content: props.intl.formatMessage(messages.helperContent),
        },
        placeholder: props.intl.formatMessage(messages.placeholder),
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadDemographicsOccupationStep,
    submitAction: submitDemographicsOccupationStep,
    undoAction: undoDemographicsOccupationStep,
});

export function reloadDemographicsOccupationStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'DemographicsOccupationStep',
        );
        if (previousStep) {
            const {currentOccupation} = getState().data.accounts.patient;
            return {
                answer: currentOccupation,
            };
        }
    };
}

export function submitDemographicsOccupationStep({answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'currentOccupation',
                    toReplace: answer,
                }),
            );
        }
    };
}

export function undoDemographicsOccupationStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'currentOccupation',
                toReplace: null,
            }),
        );
    };
}
