import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {
    demographicDurationAnswers,
    translateAnswers,
} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

export const messages = defineMessages({
    quitTitle: {
        id: 'consultations.DemographicsSmokingDurationStep.quitTitle',
        defaultMessage: 'quitTitle',
    },
    cusmTitle: {
        id: 'consultations.DemographicsSmokingDurationStep.cusmTitle',
        defaultMessage: 'cusmTitle',
    },
    placeholder: {
        id: 'consultations.DemographicsSmokingDurationStep.placeholder',
        defaultMessage: 'placeholder',
    },
});

/*
    Clinical:
    N/A

    Technical:
    How long the patient has smoked for (or long they DID smoke for if they
    have already quit).

    Dependencies:
    Only shown if the user says they smoke in DemographicsSmokingStatusStep

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    mapState: (store, props) => ({
        placeholder: props.intl.formatMessage(messages.placeholder),
        title: props.intl.formatMessage(
            messages[`${props.smoking.toLowerCase()}Title`],
        ),
        options: translateAnswers(demographicDurationAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsSmokingDurationStep,
    submitAction: submitDemographicsSmokingDurationStep,
    undoAction: undoDemographicsSmokingDurationStep,
});

export function reloadDemographicsSmokingDurationStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) =>
                step.component.type === 'DemographicsSmokingDurationStep',
        );
        if (previousStep) {
            const {
                smokingDuration,
                smokingDurationTimeFrame,
            } = getState().data.accounts.patient;
            return {
                answer: {
                    num: smokingDuration,
                    option: smokingDurationTimeFrame,
                },
            };
        }
    };
}

export function submitDemographicsSmokingDurationStep({answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'smokingDuration',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updatePatient({
                    field: 'smokingDurationTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoDemographicsSmokingDurationStep() {
    return async function(dispatch) {
        await dispatch(
            updatePatient({
                field: 'smokingDuration',
                toReplace: null,
            }),
        );
        await dispatch(
            updatePatient({
                field: 'smokingDurationTimeFrame',
                toReplace: null,
            }),
        );
    };
}
