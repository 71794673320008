import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {addStep} from 'consultations/actions/formActions.js';
import {
    drugFrequencyAnswers,
    DRUGS_NOT_ANYMORE,
    DRUGS_YES,
    translateAnswers,
} from 'clinical/helpers.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        // eslint-disable-next-line max-len
        id:
            'consultations.DemographicsRecreationalDrugStatusStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    How often does the patient consume recreational drugs (if at all)?

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        answers: translateAnswers(drugFrequencyAnswers, props.intl),
    }),
    reloadAction: reloadDemographicsRecreationalDrugStatusStep,
    submitAction: submitDemographicsRecreationalDrugStatusStep,
    undoAction: undoDemographicsRecreationalDrugStatusStep,
});

export function reloadDemographicsRecreationalDrugStatusStep() {
    return function (dispatch, getState) {
        const store = getState();
        const previousStep = store.form.previousSteps.find(
            (step) =>
                step.component.type ===
                'DemographicsRecreationalDrugStatusStep',
        );
        if (previousStep) {
            const {recDrugFrequency} = store.data.accounts.patient;
            return {
                answer: {id: recDrugFrequency},
            };
        }
    };
}

export function submitDemographicsRecreationalDrugStatusStep(
    {stepId, answer},
    reload,
) {
    return async function (dispatch) {
        if (!reload) {
            await dispatch(
                updatePatient({
                    field: 'recDrugFrequency',
                    toReplace: answer.id,
                }),
            );
        }
        if (answer.id === DRUGS_YES.id || answer.id === DRUGS_NOT_ANYMORE.id) {
            dispatch(
                addStep({
                    type: 'DemographicsRecreationalDrugChoicesStep',
                    parentId: stepId,
                }),
            );
        }
    };
}

export function undoDemographicsRecreationalDrugStatusStep() {
    return async function (dispatch) {
        await dispatch(
            updatePatient({
                field: 'recDrugFrequency',
                toReplace: null,
            }),
        );
    };
}
