import {ACCOUNTS_FEEDBACK_API} from 'apiEndpoints.js';
import actions from 'better_consult/actions/types.js';
import {addSteps} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import http from 'http.js';
import CheckpointQuestion from 'questions/components/CheckpointQuestion.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

/*
    Clinical:
    N/A

    Technical:
    This step will hit the feedback questions API and add the corresponding
    FeedbackQuestionSteps based on the response.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckpointQuestion,
    reloadAction: reloadFeedbackQuestionCheckpointStep,
    submitAction: submitFeedbackQuestionCheckpointStep,
    undoAction: undoFeedbackQuestionCheckpointStep,
});

export function reloadFeedbackQuestionCheckpointStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'FeedbackQuestionCheckpointStep',
        );
        if (previousStep) {
            return {};
        }
    };
}

export function submitFeedbackQuestionCheckpointStep({stepId}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            let questions = await http.get({
                url: ACCOUNTS_FEEDBACK_API,
            });
            questions = questions.map(snakeToCamelObjectKeys);
            dispatch({
                type: actions.data.ADD_FEEDBACK_QUESTIONS,
                payload: questions,
            });
        }
        const {feedbackQuestions} = getState().data.accounts;
        const steps = Object.keys(feedbackQuestions).map((key) => {
            const feedbackQuestion = feedbackQuestions[key];
            return {
                type: 'FeedbackQuestionStep',
                props: {
                    qid: feedbackQuestion.id,
                    title: feedbackQuestion.name,
                    hasNoAnswerOption: feedbackQuestion.allowNoAnswer,
                    answers: feedbackQuestion.answers.map((answer) => ({
                        id: answer.id,
                        text: answer.name,
                    })),
                    questionType: feedbackQuestion.type,
                },
            };
        });
        dispatch(
            addSteps({
                steps,
                parentId: stepId,
            }),
        );
    };
}

export function undoFeedbackQuestionCheckpointStep() {
    return function(dispatch) {
        dispatch({type: actions.data.CLEAR_FEEDBACK_QUESTIONS});
    };
}
