import {defineMessages} from 'react-intl';

import actions from 'better_consult/actions/types.js';
import {
    PROBLEM_TYPE_CONDITION,
    PROBLEM_TYPE_FREE_TEXT,
    PROBLEM_TYPE_PRESCRIPTION,
    PROBLEM_TYPE_PRESCRIPTION_RFV,
    PROBLEM_TYPE_SYMPTOM,
} from 'clinical/helpers.js';
import {
    submitCondition,
    submitFreeTextProblem,
    submitMedicationCourse,
    submitSymptom,
    undoCondition,
    undoFreeTextProblem,
    undoMedicationCourse,
    undoSymptom,
} from 'consultations/actions/consultationActions.js';
import {addStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    disabledResultText: {
        id: 'consultations.FreeTextProblemConfirmStep.disabledResultText',
        defaultMessage: 'disabledResultText',
    },
    questionTitle: {
        id: 'consultations.FreeTextProblemConfirmStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    noAnswer: {
        id: 'consultations.FreeTextProblemConfirmStep.noAnswers',
        defaultMessage: 'noAnswer',
    },
});

/*
    Clinical:
    N/A

    Technical:
    Displays the list of problems from the ProblemSearchStep autocomplete and
    encourages the patient to select one of these over the free-text answer.

    Dependencies:
    Only added if a free-text symptom is added by the ProblemSearchStep when
    there are actual problems available to select from.
*/

export default createStep({
    question: RadioQuestion,
    props: {
        hasNoAnswerOption: true,
    },
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        noAnswerOptionText: `${props.intl.formatMessage(messages.noAnswer)} "${
            props.searchValue
        }"`,
        answers: props.answers.map((answer) =>
            answer.disabled
                ? {
                      ...answer,
                      text:
                          `${answer.text} ` +
                          props.intl.formatMessage(
                              messages.disabledResultText,
                          ),
                  }
                : answer,
        ),
    }),
    reloadAction: reloadFreeTextProblemConfirmStep,
    submitAction: submitFreeTextProblemConfirmStep,
    undoAction: undoFreeTextProblemConfirmStep,
});

export function reloadFreeTextProblemConfirmStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const freeTextProblemConfirmSteps = store.form.steps.filter(
            (step) => step.component.type === 'FreeTextProblemConfirmStep',
        );
        const currentStepIndex = freeTextProblemConfirmSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'FreeTextProblemConfirmStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (!previousStep) {
            return;
        }
        const {
            problem,
            searchValue,
            shouldAddSteps,
        } = previousStep.component.props;
        const result = {searchValue};
        const answer = {
            id: null,
            type: PROBLEM_TYPE_FREE_TEXT,
            freeText: true,
            text: problem.displayText,
        };
        switch (problem.type) {
            case PROBLEM_TYPE_SYMPTOM:
                answer.id = problem.symptomId;
                answer.type = PROBLEM_TYPE_SYMPTOM;
                answer.freeText = false;
                break;
            case PROBLEM_TYPE_CONDITION:
                answer.id = problem.conditionId;
                answer.type = PROBLEM_TYPE_CONDITION;
                answer.freeText = false;
                break;
            case PROBLEM_TYPE_PRESCRIPTION:
                result.medicationCourse =
                    store.data.consultations.medicationCourses[
                        problem.medicationCourseId
                    ];
                answer.id = problem.medicationSearchTermId;
                answer.type = PROBLEM_TYPE_PRESCRIPTION;
                answer.freeText = false;
                break;
            case PROBLEM_TYPE_PRESCRIPTION_RFV:
                answer.id = problem.prescriptionId;
                answer.type = PROBLEM_TYPE_PRESCRIPTION_RFV;
                answer.freeText = false;
                break;
        }
        result.answer = answer;

        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.problem',
                value: problem,
            },
        });
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.shouldAddSteps',
                value: shouldAddSteps,
            },
        });
        return result;
    };
}

export function submitFreeTextProblemConfirmStep(
    {stepId, answer, medicationCourse},
    reload,
) {
    return async function (dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {searchValue} = step.component.props;
        if (answer.id === null) {
            await dispatch(
                submitFreeTextProblem(
                    {
                        stepId,
                        text: searchValue,
                    },
                    reload,
                ),
            );
        } else {
            let displayText;
            if (!reload) {
                displayText = answer.synonyms.find((synonym) => {
                    const synonymText = synonym.toLowerCase();
                    if (synonymText.includes(searchValue.toLowerCase())) {
                        return answer.text.toLowerCase().includes(synonymText);
                    }
                    return false;
                });
            }
            switch (answer.type.toLowerCase()) {
                case PROBLEM_TYPE_SYMPTOM:
                    await dispatch(
                        submitSymptom(
                            {
                                stepId,
                                symptomId: answer.id,
                                displayText,
                            },
                            reload,
                        ),
                    );
                    break;
                case PROBLEM_TYPE_CONDITION:
                    await dispatch(
                        submitCondition(
                            {
                                stepId,
                                conditionId: answer.id,
                                displayText,
                            },
                            reload,
                        ),
                    );
                    break;
                case PROBLEM_TYPE_PRESCRIPTION:
                    await dispatch(
                        submitMedicationCourse(
                            {
                                stepId,
                                medicationSearchTermId: answer.id,
                                medicationCourse,
                                isPrescriptionReasonForVisit: true,
                            },
                            reload,
                        ),
                    );
                    // TODO - add MedicationForProblemsStep in #25037
                    break;
                case PROBLEM_TYPE_PRESCRIPTION_RFV:
                    if (!reload) {
                        dispatch({
                            type: actions.form.SET_STEP_ATTR,
                            payload: {
                                stepId,
                                field: 'component.props.problem',
                                value: {
                                    type: PROBLEM_TYPE_PRESCRIPTION_RFV,
                                    prescriptionId: answer.id,
                                    displayText,
                                },
                            },
                        });
                    }
                    dispatch(
                        addStep({
                            type: 'MedicationForPrescriptionStep',
                            parentId: stepId,
                        }),
                    );
                    // TODO - add MedicationForProblemsStep in #25037
                    break;
            }
        }
    };
}

export function undoFreeTextProblemConfirmStep({stepId, answer}) {
    return async function (dispatch) {
        if (answer.id === null) {
            await dispatch(undoFreeTextProblem(stepId));
        } else {
            switch (answer.type.toLowerCase()) {
                case PROBLEM_TYPE_SYMPTOM:
                    await dispatch(undoSymptom(stepId));
                    break;
                case PROBLEM_TYPE_CONDITION:
                    await dispatch(undoCondition(stepId));
                    break;
                case PROBLEM_TYPE_PRESCRIPTION:
                    await dispatch(undoMedicationCourse({stepId}));
                    break;
                case PROBLEM_TYPE_PRESCRIPTION_RFV:
                    dispatch({
                        type: actions.form.SET_STEP_ATTR,
                        payload: {
                            stepId,
                            field: 'component.props.problem',
                            value: undefined,
                        },
                    });
                    break;
            }
        }
    };
}
